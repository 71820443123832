import React from "react";
import "./componentsStyle/Footer.css";
import PDF from "../assets/newsletter/newsletter.pdf";

const Footer = () => {
  return (
    <React.Fragment>
      <div style={{ position: "relative", top: "2vw" }}>
        <div className="sub-footer">
          <div
            className="container"
            // style={{ height: "65px" }}
          >
            <div className="row">
              <div className="col-md-6">
                <h2 className="pull-left" style={{ fontFamily: "Apercu" }}>
                  <span className="color-green">Download</span> our Newsletter
                </h2>
              </div>
              <div className="col-md-6">
                <h2 className="text-center">
                  <a
                    target="_blank"
                    className="btn btn-block btn-lg btn-success"
                    style={{ fontFamily: "Apercu" }}
                    href={PDF}
                  >
                    Download
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="container">
            <p className="pull-left"   style={{ fontFamily: "Apercu" }}>
              All Rights Reserved{" "}
              <span className="color-green">&copy; Le Banyan De PEC</span> 2024
            </p>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default Footer;

import React, { useState } from "react";
import Logo from "../assets/images/main-home-img/companyBlueLogo.jpg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./componentsStyle/Navbar.css";
import { Logout } from "../serviceApi/Auth-Services";
// import Logo1 from "../assets/images/aboutus.jpg";
import blueLogo from "../assets/images/main-home-img/companyBlueLogo.jpg";
import SpinnerWrapper from "../views/spinner";
import {
  BENEFICIARY,
  CONTRIBUTION,
  EDUCATION_FUNDS,
  FINANCIAL,
  REGISTERED_MEMBERS,
} from "../serviceApi/constant";

const HEADER_LOGO = Logo;

const Header = () => {
  // const navigate = useNavigate();
  const getScholarshIdUser = localStorage.getItem("userDetails");
  const getUserDetails = localStorage.getItem("userDetails");
  const [isLoading, setIsLoading] = useState(false);

  const banyanAdminUser = "65788b0d85f4f71aafe8ad14";
  // const location = useLocation();

  const isActive = (match) => {
    return (
      location.pathname === "/scholarship-form" ||
      location.pathname === "/edit-scholarship-form"
    );
  };
  const logoutHandler = () => {
    setIsLoading(true);
    localStorage.clear();
    Logout();
    setIsLoading(false);
  };

  // const handleScroll = (event) => {
  //     navigate(event);
  // };

  return (
    <React.Fragment>
      {isLoading && <SpinnerWrapper />}
      <nav className="navbar navbar-expand-lg p-0 fixed-top navbar-default">
        <div id="app_home" className="container" style={{ paddingLeft: "10px" }}>
          <img src={blueLogo} alt="header_logo" width={120} height={90} />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item mx-2 user_nav">
                <a href="/" className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-item mx-2 user_nav">
                <NavLink to="/about" className="nav-link">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item mx-2 user_nav">
                <NavLink to="/objective" className="nav-link">
                  Objective
                </NavLink>
              </li>
              <li className="nav-item mx-2 user_nav">
                <NavLink to="/exec-board" className="nav-link">
                  Exec Board
                </NavLink>
              </li>
              <li className="nav-item mx-2 user_nav">
                <NavLink to="/events" className="nav-link">
                  Events
                </NavLink>
              </li>
              <li className="nav-item mx-2 dropdown user_nav">
                <NavLink
                  className="nav-link dropdown-toggle user_nav"
                  to="/objective/#Education"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dashboards
                </NavLink>
                <ul className="dropdown-menu p-0">
                  {getUserDetails && (
                    <>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dropdown-item user_nav"
                          href={BENEFICIARY}
                        >
                          Beneficiary
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dropdown-item user_nav"
                          href={EDUCATION_FUNDS}
                        >
                          Education Corpus
                        </a>
                      </li>
                    </>
                  )}
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item user_nav"
                      href={CONTRIBUTION}
                    >
                      Batch-wise contribution
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item user_nav"
                      href={FINANCIAL}
                    >
                      Financial Documents
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item user_nav"
                      href={REGISTERED_MEMBERS}
                    >
                      Registered Members
                    </a>
                  </li>
                </ul>
              </li>

              {getUserDetails && (
                <>
                  <li className="nav-item mx-2 user_nav">
                    {/* <NavLink
                      to={
                        localStorage.getItem("existingForm")
                          ? `/edit-scholarship-form?id=${getScholarshIdUser}`
                          : `/scholarship-form`
                      }
                      className="nav-link"
                    >
                      Scholarship
                    </NavLink> */}
                    <NavLink className="nav-link" to={"/scholarship-form"}>Scholarship</NavLink>
                  </li>
                  <li className="nav-item mx-2 user_nav">
                    <Link onClick={logoutHandler} className="nav-link">
                      Logout
                    </Link>
                  </li>
                  {getUserDetails.includes(banyanAdminUser) && (
                    <li className="nav-item mx-2 user_nav">
                      <NavLink to="/admin" className="nav-link">
                        Activities
                      </NavLink>
                    </li>
                  )}
                  <li className="nav-item mx-2 user_nav">
                    <Link to={`/user-profile?id=${getScholarshIdUser}`} className="nav-link">
                      User Profile
                    </Link>
                  </li>
                </>
              )}
              {!getUserDetails && (
                <>
                  <li className="nav-item mx-2 user_nav">
                    <NavLink to="/login" className="nav-link">
                      Login
                    </NavLink>
                  </li>
                  <li className="nav-item mx-2 user_nav">
                    <NavLink to="/register" className="nav-link">
                      Register
                    </NavLink>
                  </li>
                  <li className="nav-item mx-2 user_nav">
                    <NavLink to="/contact-us" className="nav-link">
                      Contact Us
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Header;
